import React from "react";
import HeaderImage from "../../images/hero-image.jpg";

const Header = ({ pageContext }) => (
  <section id="header" className={`header header-${pageContext}`}>
    <div>
      <img src={HeaderImage} />
    </div>
  </section>
);

export default Header;
